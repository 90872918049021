.subheader {
  background: #f3f3f3;
  border-bottom: 1px solid #ddd;
  min-height: calc(100vh - 40px);
}

.non-packager {
  margin-top: 20vh;
}

.autosuggest {
  width: 90%;
  position: absolute;
  z-index: 500;
}

.suggestions {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-color: rgb(183, 198, 218);
  border-radius: 4 4 4 4;
  border-width: 1px;
  border-style: solid;
}

.suggestions ul {
  padding-left: 0;
}

.suggestions ul li {
  padding-left: 1em;
}

.pillbox {
  padding-top: 3.5em;
}

.remove-item {
  padding-left: 0.5em;
  cursor: pointer;
}
