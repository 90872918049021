body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Cantarell', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  height: 100%;
  padding-right: 0 !important;
  overflow: visible;
}

.pointer {
  cursor: pointer;
}

.tooltip .tooltip-inner {
  font-family: monospace;
  text-align: left !important;
  max-width: 350px;
}
