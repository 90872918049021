svg:not(:root) {
  width: auto;
  height: 100%;
}

.timeline {
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0 auto;
}

.timeline td {
  padding: 0;
  font-size: 0.8em !important;
  width: 150;
  text-align: center;
  vertical-align: top;
}
