.package-calendars .card-columns {
  font-size: 0.8em !important;
}

.package-calendar-events {
  font-weight: normal;
}

.package-calendar-date {
  width: 5rem;
  vertical-align: top;
}

.package-calendar-dateinfo {
  font-weight: normal;
}

@media (min-width: 576px) {
  .package-calendars .card-columns {
    column-count: 1;
  }
}

@media (min-width: 768px) {
  .package-calendars .card-columns {
    column-count: 4;
  }
}

.text-ellipsis {
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
